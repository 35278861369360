export default{
    lista:{
        method:'get',
        url:'vagas/lista'
    },
    cadastra:{
        method:'post',
        url:'depedente/cadastro'
    },
    delete:{
        method:'delete',
        url:'depedente/deletar{/id}'
    },
    editar:{
        method:'get',
        url:'depedente/editar{/id}'
    },
    update:{
        method:'post',
        url:'depedente/update'
    },
    busca:{
        method:'get',
        url:'depedente/busca{/id}'
    },
}