import serves from "@/http";
// import * as storage from "../storage";
import * as types from "./mutations-type";
export const ActionCadastro = ({dispatch},payload)=>{
    return serves.depedentes.cadastra(payload).then(res => {
        dispatch('ActionSetMsg',res)
    }).catch(err => {
        dispatch('ActionSetMsg',err)
    })
}
export const ActionLista = ({dispatch},payload)=>{
    return serves.vagas.lista(payload).then(res => {
        
        dispatch('ActionSetLista',res)
    }).catch(err => {
        dispatch('ActionSetLista',res)
    })
}
export const ActionDeletar = ({dispatch},payload)=>{
    return serves.depedentes.delete(payload).then(res => {
        console.log(res);
        dispatch('ActionSetMsg',res)
    }).catch(err => {
        dispatch('ActionSetMsg',res)
    })
}
export const ActionEditar = ({dispatch},payload)=>{
    return serves.depedentes.editar(payload).then(res => {
        dispatch('ActionSetEditar',res)
    }).catch(err => {
        dispatch('ActionSetEditar',err)
    })
}
export const ActionUpdate = ({dispatch},payload)=>{
    return serves.depedentes.update(payload).then(res => {
        dispatch('ActionSetMsg',res)
    }).catch(err => {
        dispatch('ActionSetMsg',err)
    })
}
export const ActionBusca = ({dispatch},payload)=>{
    return serves.depedentes.busca(payload).then(res => {
        dispatch('ActionSetLista',res)
    }).catch(err => {
        dispatch('ActionSetLista',err)
    })
}
export const ActionSetLista = ({commit},payload) =>{
    commit(types.SET_LISTA,payload)
}
export const ActionSetMsg = ({commit},payload) =>{
    commit(types.SET_MSG,payload)
}
export const ActionSetEditar = ({commit},payload) =>{
    commit(types.SET_EDITAR,payload)
}
