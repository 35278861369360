import { serves as auth } from "@/modulos/auth";
import { serves as principal } from "@/modulos/home";
import { serves as curriculo } from "@/modulos/curriculo";
import { serves as admin } from "@/modulos/admin";
import { serves as vagas } from "@/modulos/vagas";
export default{
    auth,
    curriculo,
    admin,
    principal,
    vagas,
}
