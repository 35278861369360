export default[
    {
        path: '/depedentes/cadastro/:id',
        name: 'cadastro', 
        component: () => import(/* webpackChunkName: "Login" */ './page/cadastro.vue')
    },
    {
        path: '/depedentes/lista/:id',
        name: 'lista', 
        component: () => import(/* webpackChunkName: "Login" */ './page/lista.vue')
    },
    {
        path: '/depedentes/editar/:trabalhador/:id',
        name: 'lista', 
        component: () => import(/* webpackChunkName: "Login" */ './page/editar.vue')
    },
]